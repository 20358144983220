<template>
  <div class="chart" style="height:230px; width:100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from 'lodash';
export default {
  props: {
    options: {
        type: Object,
        require: true,
        default: () => ({})
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");

      var barDataOK = this.options.barDataOK || [];
      var barDataErrors = this.options.barDataErrors || [];
      var date = this.options.date || [];
      this.chart.setOption({
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow',
                  label: {
                      show: true,
                      backgroundColor: '#333',
                  },
              },
          },
          grid: {
              left: '2%',
              right: '4%',
              bottom: '15%',
              top: '5%',
              containLabel: true,
          },
          legend: {
              data: [this.options.okText, this.options.errorText],
              icon:'circle',
              left: 'center',
              bottom:'0',
              show: true,
              itemHeight: 8,
              itemWidth: 8
          },
          xAxis: [
              {
                type: 'category',
                axisLine: {
                  show: false, //隐藏X轴轴线
                  lineStyle: {
                    // color: 'fff',
                  },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                      padding: [2, 0, 0, 0],
                      color: '#666',
                      fontSize: 12
                    },
                },
                axisTick: {
                  show: false, //隐藏X轴刻度
                },
                axisPointer: {
                  type: 'shadow',
                },
                
                data: date,
              },
          ],
          yAxis: [
              {
                type: 'value',
                axisLabel: {
                  textStyle: {
                      color: '#666',
                      fontSize: 12
                  },
                },
                splitLine: {
                  show: false
                },
                splitArea: { show: false },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                nameGap: 30
              },
          ],
          series: [
              {
                name: this.options.okText,
                type: 'bar',
                stack: '总量',
                barWidth: 10,
                //  barCategoryGap:'5%', // 设置柱柱子之间的间距
                itemStyle: {
                    normal: {
                      barBorderRadius:  [10, 10, 0, 0],
                      // color: 'rgba(255, 173, 95, 1)',
                      color: 'rgba(136, 126, 242, 1)',
                    },
                },
                data: barDataOK,
              },
              {
                name: this.options.errorText,
                type: 'bar',
                stack: '总量',
                barWidth: 10,
                itemStyle: {
                    normal: {
                      barBorderRadius:  [0, 0, 10, 10],
                      // color: 'rgba(136, 126, 242, 1)',
                      color: 'rgba(255, 173, 95, 1)',
                    },
                },
                data: barDataErrors,
              }
          ],
      });
    }
  },
  created: function() {},
  computed: {},
  watch: {
    options: {
      deep:true,
      handler() {
        this.initChart()
      },
    },
  },
};
</script>
