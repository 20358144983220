<template>
  <div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="taskName"
        :label="$t('dashboard.ordinaryStore.TaskName')"
        sortable
        align="center"
      ></el-table-column>
      <el-table-column prop="taskType" align="center" :label="$t('dashboard.ordinaryStore.Task')">
        <template slot-scope="scope">
          <span class="task tast-bg">
            <!-- <template v-for="(item, i) in taskTypeList">
              <span :key="i" v-if="scope.row.taskType == item.codeId">
                <icon-svg
                  :name="icons[item.codeId]"
                  style="font-size: 15px"
                ></icon-svg>
                &nbsp; {{ scope.row.taskTypeDesc }}
              </span>
            </template> -->

            <template v-for="(item, i) in icons">
              <span :key="i" v-if="scope.row.taskType == i">
                <icon-svg :name="icons[i]" style="font-size: 15px"></icon-svg>
                &nbsp; {{ scope.row.taskTypeDesc }}
              </span>
            </template>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="startDate"
        :label="$t('dashboard.ordinaryStore.BeginTime')"
        sortable
        align="center"
      ></el-table-column>
      <el-table-column
        prop="state"
        :label="$t('dashboard.ordinaryStore.Status')"
        align="center"
      >
        <template slot-scope="scope">
          <template v-for="(item, i) in taskStateList">
            <span
              class="status"
              :key="i"
              v-if="scope.row.state == item.codeId"
              :class="{
                'status-todo':
                  scope.row.state == TASK_STATE_INIT ||
                  scope.row.state == TASK_STATE_WAIT_EXPECT,
                'status-progress': scope.row.state == TASK_STATE_INPROGRESS,
                'status-complete':
                  scope.row.state == TASK_STATE_SUCCESS ||
                  scope.row.state == TASK_STATE_PART_SUCCESS,
                'status-fail': scope.row.state == TASK_STATE_ERROR,
              }"
            >
              {{ scope.row.stateDesc }}
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="processeds"
        :label="$t('dashboard.ordinaryStore.SuccessNumber')"
        sortable
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="!scope.row.processeds">0</span>
          <span v-else>{{ scope.row.processeds }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="errors"
        :label="$t('dashboard.ordinaryStore.ErrorsNumber')"
        sortable
        align="center"
      >
        <template slot-scope="scope">
          <span>
            <!-- {{ scope.row.errors }} -->
             <!-- v-if="scope.row.errors && scope.row.errors !=0" -->
            <span v-if="!scope.row.errors">0</span>
            <span v-else>{{ scope.row.errors }}</span>
            <span
              @click="handelView(scope.row)"
              class="search-icon"
              ><i class="el-icon-search"></i
            ></span>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="$t('dashboard.ordinaryStore.Failure Details')"
      :visible.sync="isVisibleDetailDialog"
      width="800px"
    >
      <el-table
        v-if="isVisibleDetailDialog"
        :data="errorList"
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          :label="$t('dashboard.ordinaryStore.seq')"
          align="center"
          prop="tagIdentity"
        />
        <el-table-column
          :label="$t('dashboard.ordinaryStore.message')"
          align="center"
          prop="message"
        />
        <el-table-column
          :label="$t('dashboard.ordinaryStore.expireTime')"
          align="center"
          prop="createTime"
        />
      </el-table>
      <pagination :tableInfo="pageParams" @changePage="changePage" />
    </el-dialog>
  </div>
</template>
<script>
import { getCodeList } from "@/libs/api/aps";

import { getErrorList } from "@/libs/api/dashboard";
import pagination from "@/components/pagination";
import {
  TASK_STATE_INIT,
  TASK_STATE_WAIT_EXPECT,
  TASK_STATE_INPROGRESS,
  TASK_STATE_PART_SUCCESS,
  TASK_STATE_SUCCESS,
  TASK_STATE_ERROR,
  TASK_TYPE_IMAGE_TASK,
  TASK_TYPE_UNBIND_TASK,
  TASK_TYPE_LED_TASK,
  TASK_TYPE_SWITCH_PAGE_TASK,
  TASK_TYPE_NFC_TASK,
  TASK_TYPE_SWITCH_LAYOUT_TASK,
  TASK_TYPE_SWITCH_TEMPLATE_TASK,
  TASK_TYPE_FORCE_TRANSMIT_TASK,
  TASK_TYPE_BIND_TASK,
} from "@/libs/enum";
export default {
  components: {
    pagination,
  },
  props: {
    tableData: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  name: "",
  data() {
    return {
      TASK_STATE_INIT,
      TASK_STATE_WAIT_EXPECT,
      TASK_STATE_INPROGRESS,
      TASK_STATE_PART_SUCCESS,
      TASK_STATE_SUCCESS,
      TASK_STATE_ERROR,

      TASK_TYPE_IMAGE_TASK,
      TASK_TYPE_UNBIND_TASK,
      TASK_TYPE_LED_TASK,
      TASK_TYPE_SWITCH_PAGE_TASK,
      TASK_TYPE_NFC_TASK,
      TASK_TYPE_SWITCH_LAYOUT_TASK,
      TASK_TYPE_SWITCH_TEMPLATE_TASK,
      TASK_TYPE_FORCE_TRANSMIT_TASK,
      TASK_TYPE_BIND_TASK,

      isVisibleDetailDialog: false,

      icons: {},
      taskTypeList: [], //任务类型
      taskStateList: [], //任务执行状态

      errorList: [], //失败日志列表
      pageParams: {
        current: 1,
        size: 10,
        total: 0,
        id: "",
      },
    };
  },
  methods: {
    changePage() {
      this.getErrorList();
    },
    handelView(item) {
      this.isVisibleDetailDialog = true;
      this.pageParams.id = item.id;
      this.getErrorList();
    },
    async getCodeList(type) {
      try {
        const { data } = await getCodeList({ cateId: type });
        if (data.state === true) {
          let arr = data.data || [];
          if (type == 12) {
            this.taskTypeList = arr; //任务类型
          } else if (type == 19) {
            this.taskStateList = arr; //任务执行状态
          }
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },

    async getErrorList() {
      try {
        let params = {
          current: this.pageParams.current,
          size: this.pageParams.size,
          orders: [],
          taskScheduleId: this.pageParams.id
        };
        const { data } = await getErrorList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          this.errorList = dataNew.records;
          this.pageParams.total = dataNew.total || 0;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
  },
  created() {
    // this.getCodeList(12);
    this.getCodeList(19);

    this.icons[TASK_TYPE_SWITCH_LAYOUT_TASK] = "bz-layout"; //Switch Layout Task
    this.icons[TASK_TYPE_SWITCH_TEMPLATE_TASK] = "bz-template"; //Switch Template Task
    this.icons[TASK_TYPE_FORCE_TRANSMIT_TASK] = "bz-transmissions"; //Transmit Task
    this.icons[TASK_TYPE_BIND_TASK] = "bz-NFC"; //Bind Task   //svg暂无
    this.icons[1210] = "bz-NFC"; //解绑 (参考的后端接口 与Unbind Task重复) //svg暂无
    this.icons[TASK_TYPE_UNBIND_TASK] = "bz-NFC"; //Unbind Task  //svg暂无
    this.icons[TASK_TYPE_IMAGE_TASK] = "bz-image"; //Image Task
    this.icons[TASK_TYPE_LED_TASK] = "bz-LED"; //LED Task
    this.icons[TASK_TYPE_SWITCH_PAGE_TASK] = "bz-switchPage"; //Switch Page Task
    this.icons[TASK_TYPE_NFC_TASK] = "bz-NFC"; //NFC Task  //svg暂无
  },
};
</script>
<style lang="scss" scoped>
.status,
.task {
  padding: 1px 13px;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  // font-weight: bold;
  color: #07334b;
}
.status-todo {
  background: rgba(202, 210, 215, 1);
}
.status-fail {
  background: #fecaca;
}
.status-progress {
  background: #c1e9ff;
}
.status-complete {
  background: #bbf7d0;
}
.tast-bg {
  background: #f2f2f2;
  color: #666666;
}
.search-icon {
  margin-left: 10px;
  cursor: pointer;
}
</style>