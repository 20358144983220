<template>
    <div class="dashboard-source_link-card dashboard-card screen-card-bg">
        <div class="title">
            {{options.title}}
        </div>
        <div class="center-wrapper ">
            <div class="chart-img-wrapper">
                <pie-other-chart :options="options"></pie-other-chart>
            </div>
            <div class="chart-label-wrapper">
                <span class="chart-label" v-for="(itemC, c) in options.colors" :key="c">
                    <template  v-for="(item, i) in options.chartData">
                        <div v-if="c===i" :key="i" :class="{'label-bg': c === i}">
                            <icon-svg class="chart-label-svg" name="bubmer_bg" :style="{color: itemC}"></icon-svg>
                            
                            <div class="chart-label-text">{{item.value}}</div>
                        </div>
                    </template>
                </span>
                
            </div>
        </div>
        <div class="bottom-wrapper">
            <div v-for="(item, i) in options.chartData" :key="i">
                <div class="type light-red-color" :style="{background: 'rgba(' + item.customColor + ',0.07)', color: 'rgba(' + item.customColor + ')'}">
                    <icon-svg :style="{color: 'rgba(' + item.customColor + ')'}"  :name="item.icon"></icon-svg>
                    {{ item.name }} 
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import PieOtherChart from '../echarts/PieOtherChart.vue'
export default {
  components: {
    PieOtherChart,
  },
  props: {
    options: {
        type: Object,
        require: true,
        default: () => ({})
    }
  },
  data() {
    return {

    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.dashboard-source_link-card {
    .dashboard-card {
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
        border-radius: 12px;
        padding: 20px 30px;
    }
    .flex-between {
        display: flex;
        justify-content: space-between;
    }
    // height: 261px;
    .title {
        // font-size: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
    }
    
    
    .center-wrapper {
        .chart-img-wrapper {
            width: 150px;
        }
        display: flex;
        justify-content: space-around;
        .chart-label-wrapper {
            // margin-top: 26px;
            margin-top: 20px;
            .chart-label {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 19px;
                .label-bg {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    .chart-label-svg {
                        // font-size: 50px;
                        font-size: 57px;
                    }
                    .chart-label-text {
                        position: absolute;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
                

            }
            .chart-label:nth-of-type(2) {
                margin-top: -20px;
            } 
        }
    }
    .bottom-wrapper {
        display: flex;
        justify-content: space-around;
        .type {
          margin: 0 auto;
          text-align: center;
          background: rgba(59, 130, 246, .1);
          border-radius: 20px;
        //   padding: 7px 12px;
        padding: 4px 12px;
        //   font-size: 15px;
          font-size: 14px;
          min-width: 108px;
        }
        .svg-icon {
          font-size: 19px;
        }
    }
   
}
</style>
