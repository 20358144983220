<template>
  <div class="dashboard-store-type dashboard-card screen-card-bg">
    <div class="title">
      {{ $t("dashboard.admin.Store Exception Type Statistics") }}
    </div>
    <div class="center-wrapper">
      <div class="item" v-for="(item, i) in options.dataList" :key="i">
        <div style="width: 88%">
          <div class="item-name" :style="{ color: item.color }">
            {{ item.name }}
          </div>
          <el-progress
            :stroke-width="10"
            :show-text="false"
            :percentage="
              Number(item.number)
                ? (Number(item.number) / Number(options.maxNum)) * 100
                : 0
            "
            :color="item.color"
          ></el-progress>
        </div>
        <div class="item-number">
          <div class="item-number-text">{{ item.number }}</div>
          <icon-svg
            class="item-number-svg"
            name="bubmer_bg"
            :style="{ color: item.color }"
          ></icon-svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    options: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.dashboard-card {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 20px 30px;
  .title {
    font-size: 16px;
  }
}
.dashboard-store-type {
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .title {
    // font-size: 20px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 39px;
  }

  .center-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
      width: 100%;
      margin-bottom: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-name {
        margin-bottom: 5px;
        width: 165px;
        font-size: 14px;
      }
      .item-number {
        width: 70px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 25px;
        .item-number-text {
          position: absolute;
          color: #ffffff;
          font-size: 14px;
        }
        .item-number-svg {
          font-size: 65px;
        }
      }
    }
  }
}
</style>
