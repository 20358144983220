<template>
  <div class="chart" style="height: 230px; width: 100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "lodash";
export default {
  props: {
    options: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            show: true,
            status: "shadow",
            z: -1,
            shadowStyle: {
              color: "rgba(81, 138, 252, 0.1)",
            },
            type: "shadow",
          },
        },
        grid: {
          left: "2%",
          right: "2%",
          bottom: "6%",
          top: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            position: "bottom",
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#EFEFEF",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              rotate: 0,
              fontSize: 12,
              color: "#666",
              // interval: 0, // 坐标刻度之间的显示间隔，默认就可以了
              // rotate: 38, // 调整数值改变倾斜的幅度（范围-90到90）
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(193, 207, 220, 1)",
                width: 0,
                type: "dashed",
              },
            },
            gridIndex: 0,
            data: this.options.XData,
          },
        ],
        yAxis: [
          {
            type: "value",
            position: "left",
            minInterval: 2,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#EFEFEF",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              rotate: 0,
              fontSize: 12,
              color: "#666",
            },
            splitLine: {
              show: false,
            },
            splitArea: { show: false },
          },
        ],
        series: [
          {
            name: this.options.title,
            type: "bar",
            data: this.options.valueData,
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                color: "rgba(94, 130, 255, 1)",
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
          },
        ],
      });
    },
  },
  created: function () {},
  computed: {},
  watch: {
    options: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
};
</script>
