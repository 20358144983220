<template>
  <div class="ordinary-rank_warpper">
    <div
      class="flex-between list-item"
      v-for="(item, i) in options.dataList"
      :key="i"
    >
      <el-progress
        style="width: 88%"
        :stroke-width="10"
        :show-text="false"
        :percentage="
          Number(item.value)
            ? ((Number(item.value)) /
                Number(options.maxNum)) *
              100
            : 0
        "
        :color="'rgba(' + item.customColor + ')'"
      ></el-progress>
      <span>{{ item.value }}</span>
    </div>
    <div class="list-name">
      <div
        class="item-name"
        :style="{ background: 'rgba(' + item.customColor + ',0.07)' }"
        v-for="(item, i) in options.dataList"
        :key="i"
      >
        <span
          class="point"
          :style="{ background: 'rgba(' + item.customColor + ')' }"
        ></span>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
// 普通门店排行
export default {
  name: "OrdinaryRank",
  components: {},
  props: {
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    // this.options.dataList.forEach(item => {
    //     item.val = Number(item.val);
    //     this.total += item.val;
    // });
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.ordinary-rank_warpper {
  position: relative;
  // height: 80%;
  height: 73%;
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 11px;
    margin-bottom: 17px;
  }
  .list-name {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    flex-wrap: wrap;
    width: 100%;
  }
  .item-name {
    // padding: 4px 13px;
    padding: 0 13px;
    line-height: 25px;
    background: #fff4f4;
    border-radius: 6px;
    // font-size: 12px;
    font-size: 12px;
    margin-right: 5px;
    margin-top: 5px;
  }
  .point {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: inline-block;
  }
  .item-name > span {
    margin-right: 3px;
  }
}
</style>
