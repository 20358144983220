<template>
  <div class="chart" style="height:230px; width:100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from 'lodash';
export default {
  props: {
    options: {
        type: Object,
        require: true,
        default: () => ({})
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
    this.chart = echarts.init(this.$el, "macarons");
    let names = this.options.data || [];
    let date = this.options.date || [];
    // let date = ['10:00','11:00', '12:00','13:00', '14:00','15:00','16:00']
    // let names = [
    //    {
    //     name: 'G1 2.8',
    //     value: [11,20,30,26,53,24,50],
    //   },
    //   {
    //     name: 'G1 2.7',
    //     value: [11,20,30,26,53,24,55],
    //   },
    //   {
        
    //     name: 'G1 2.6',
    //     value: [11,20,30,26,53,24,58]
    //   },
    //   {
        
    //     name: 'G1 2.5',
    //     value: [11,20,30,26,53,24,30]
    //   }];
      let seriesArr = [];
      names.forEach((item, i)=> {
        seriesArr.push({
          name: item.name,
          type: 'bar',
          barWidth: 10,
          stack: '数量',
          data: item.value,
          itemStyle: {
            normal: {
              barBorderRadius:  i === (names && (names.length - 1)) ? [10, 10, 0, 0] : [0, 0, 0, 0]
            },
          },
        })
      })
    this.chart.setOption({
          title: {
            text: '',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter(params){
              let str = '';
              str = ` 
                <div>
                  <span> ${ params[0].name } </span>  
                  <div>`;    
              for (let i = params.length-1; i >= 0; i--) {
                str += `
                  <div>
                      <span> ${ params[i].marker }${ params[i].seriesName }: </span>
                      <span>${ params[i].value } </span>
                  </div>
                `;
              }
      
              str += ` 
                      </div>
                  </div>
              `;
              return str;
            }
          },
          legend: {
            icon:'circle',
            left: 'center',
            bottom:'0',
            show: true,
            itemHeight: 8,
            itemWidth: 8
          },
    
          grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            top: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            splitLine: {
              show: true
            },
            axisLine: {
              lineStyle: {
                color: '#EFEFEF',
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#666',
                fontSize: 12
              },
            },
            data: date,
          },
          yAxis: [
              {
                type: 'value',
                axisLabel: {
                  textStyle: {
                      color: '#666',
                      fontSize: 12
                  },
                },
                splitLine: {
                  show: false
                },
                splitArea: { 
                  show: false 
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
          ],
          series:  seriesArr
        });
    }
  },
  created: function() {},
  computed: {},
  watch: {
    options: {
      deep:true,
      handler() {
        this.initChart()
      },
    },
  },
};
</script>
