<template>
  <div class="margin_b20 card-welcome-wrapper">
    <div class="bg-success bz-welcome-card">
      <div class="bz-welcome-card_bigTitle text-white">
         {{ $t("dashboard.ordinaryStore.Hello") }}, {{ userData.username }}
      </div>
      <div class="text-white bz-welcome-card_text">
        {{ $t("dashboard.ordinaryStore.WelcomeTo") }}
      </div>
      <div class="current-date text-white">
        <span class="date">{{
          moment(currentOptions.SystemTime).format("YYYY-MM-DD ")
        }}</span>
        <br />
        <span class="time">{{
          moment(currentOptions.SystemTime).format("HH:mm:ss")
        }}</span>
      </div>
    </div>
    <div class="bz-welcome-card_bottom-total flex-between">
      <div class="update-today bottom-card">
        <div class="title">{{ $t("dashboard.ordinaryStore.eSLUpdateToday") }}</div>

        <div class="total-number">
          {{
            `${
              currentOptions.Total || currentOptions.Total == 0
                ? currentOptions.Total
                : "-"
            }`
          }}
        </div>
        <div class="type">
          <icon-svg name="bz-elsTotal"></icon-svg>
          {{ $t("dashboard.ordinaryStore.Total") }}
        </div>
      </div>
      <div class="update-source bottom-card">
        <div class="title">{{ $t("dashboard.ordinaryStore.UpdateSource") }}</div>
        <div class="bottom-card-right-total flex-around">
          <div class="left">
            <div class="total-number light-red-color">
              {{
                `${
                  currentOptions.System || currentOptions.System == 0
                    ? currentOptions.System
                    : "-"
                }`
              }}
            </div>
            <div class="type light-red-color">
              <icon-svg class="light-red-color" name="bz-system"></icon-svg>
              {{ $t("dashboard.ordinaryStore.System") }}
            </div>
          </div>
          <div class="right">
            <div class="total-number waring-color">
              {{
                `${
                  currentOptions.PDA || currentOptions.PDA == 0
                    ? currentOptions.PDA
                    : "-"
                }`
              }}
            </div>
            <div class="type waring-color">
              <icon-svg class="waring-color" name="bz-pda"></icon-svg>
              <div :style="{ marginTop: '1px' }">{{ $t("dashboard.ordinaryStore.PDA") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    currentOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      moment,
      userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {},
    };
  },
  created() {
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.card-welcome-wrapper {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #ffffff;
  .flex-around {
    display: flex;
    justify-content: space-around;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
}
.margin_b20 {
  margin-bottom: 20px;
}
.bz-welcome-card {
  padding: 95px 48px;
  position: relative;
  margin-bottom: 0;
  height: 260px;
  border-radius: 6px;
  &_bigTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &_text {
    font-size: 14px;
  }
  .current-date {
    background: #ffffff;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0px 0px 6px 6px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 1px;
    right: 20px;
    padding: 12px 20px;
    text-align: center;
    line-height: 24px;

    .date {
      font-size: 24px;
      color: #ffffff;
    }
    .time {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &_bottom-total {
    padding:  0 20px 20px;
    margin-top: -59px;
   
    .bottom-card {
      height: 249px;
      background: #ffffff;
      // box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 7%);
      border-radius: 6px;
      z-index: 2;
      .title {
        padding: 20px;
        font-size: 16px;
        color: #333333;
        height: 30px;
      }
      .type {
        margin: 0 auto;
        text-align: center;
        background: #3b82f6;
        background: rgba(59, 130, 246, 0.1);
        border-radius: 20px;
        padding: 4px 12px;
        // font-size: 18px;
        font-size: 14px;
        color: #3b82f6;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .svg-icon {
        font-size: 19px;
        margin-right: 4px;
      }
    }
    .total-number {
      margin-top: 51px;
      margin-bottom: 6px;
      text-align: center;
      font-size: 55px;
    }
    .update-today {
      flex: 1;
      margin-right: 20px;
      box-sizing: border-box;
      .total-number {
        color: #3b82f6;
      }
      .type {
        width: 105px;
        text-align: center;
      }
    }
    .update-source {
      flex: 1;
      box-sizing: border-box;
      .light-red-color {
        color: #f16262;
      }
      .waring-color {
        color: #ff9c00;
      }
      .left {
        .type {
          background-color: rgba(241, 98, 98, 0.1);
        }
      }
      .right {
        .type {
          background-color: rgba(255, 156, 0, 0.1);
        }
      }
    }
  }
}
</style>
