<template>
  <div class="dashboard-eslUpdate_today dashboard-card screen-card-bg">
    <span class="title">{{ $t("dashboard.admin.ESL Update Today") }} </span>
    <span class="total-text">
      {{ $t("dashboard.admin.Total")
      }}<span class="number">{{ total }}</span></span
    >
  </div>
</template>
<script>
export default {
  components: {},
  props: ["total"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.dashboard-card {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 20px 30px;
  .title {
    font-size: 16px;
  }
}
.dashboard-eslUpdate_today {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    // font-size: 20px;
    color: #333333;
  }
  .total-text {
    // font-size: 20px;
    font-size: 16px;
    color: #00c0ff;
    display: flex;
    align-items: center;
    .number {
      font-size: 60px;
      color: #00c0ff;
      margin-left: 19px;
    }
  }
}
</style>
