var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-exception-table"},[_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"250"},on:{"cell-mouse-enter":_vm.startScroll,"cell-mouse-leave":_vm.stopScroll}},[_c('el-table-column',{attrs:{"prop":"customerName","label":_vm.$t('dashboard.admin.Store Name')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:{
            'ESL-style': scope.row.exType == 2,
            'APs-style': scope.row.exType == 0,
            'Interface-style': scope.row.exType == 1,
          }},[_vm._v(" "+_vm._s(scope.row.customerName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"customerCode","label":_vm.$t('dashboard.admin.Store Code')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:{
            'ESL-style': scope.row.exType == 2,
            'APs-style': scope.row.exType == 0,
            'Interface-style': scope.row.exType == 1,
          }},[_vm._v(" "+_vm._s(scope.row.customerCode)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"exType","label":_vm.$t('dashboard.admin.Reason')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:{
            'ESL-style': scope.row.exType == 2,
            'APs-style': scope.row.exType == 0,
            'Interface-style': scope.row.exType == 1,
          }},[(scope.row.exType == 2)?[_vm._v(" "+_vm._s(_vm.$t("dashboard.admin.ESL Exception"))+" ")]:(scope.row.exType == 1)?[_vm._v(" "+_vm._s(_vm.$t("dashboard.admin.Interface Exception"))+" ")]:(scope.row.exType == 0)?[_vm._v(_vm._s(_vm.$t("dashboard.admin.APs Exception"))+" ")]:_vm._e()],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }