<template>
  <div class="store-exception-table">
    <el-table
      @cell-mouse-enter="startScroll"
      @cell-mouse-leave="stopScroll"
      ref="table"
      :data="tableData"
      style="width: 100%"
      height="250"
    >
      <el-table-column
        prop="customerName"
        :label="$t('dashboard.admin.Store Name')"
      >
        <template slot-scope="scope">
          <div
            :class="{
              'ESL-style': scope.row.exType == 2,
              'APs-style': scope.row.exType == 0,
              'Interface-style': scope.row.exType == 1,
            }"
          >
            {{ scope.row.customerName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerCode"
        :label="$t('dashboard.admin.Store Code')"
      >
        <template slot-scope="scope">
          <div
            :class="{
              'ESL-style': scope.row.exType == 2,
              'APs-style': scope.row.exType == 0,
              'Interface-style': scope.row.exType == 1,
            }"
          >
            {{ scope.row.customerCode }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="exType" :label="$t('dashboard.admin.Reason')">
        <template slot-scope="scope">
          <div
            :class="{
              'ESL-style': scope.row.exType == 2,
              'APs-style': scope.row.exType == 0,
              'Interface-style': scope.row.exType == 1,
            }"
          >
            <template v-if="scope.row.exType == 2">
              {{ $t("dashboard.admin.ESL Exception") }}
            </template>
            <template v-else-if="scope.row.exType == 1">
              {{ $t("dashboard.admin.Interface Exception") }}
            </template>
            <template v-else-if="scope.row.exType == 0"
              >{{ $t("dashboard.admin.APs Exception") }}
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  name: "",
  data() {
    return {
      interval: null,
      // clientHeight: "",
    };
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      this.tableScroll(); //轮播
    });
  },
  methods: {
    startScroll() {
      clearInterval(this.interval);
      this.interval = null;
    },

    stopScroll() {
      this.tableScroll();
    },

    tableScroll() {
      const table = this.$refs.table;
      const divData = table.bodyWrapper;
      this.interval = setInterval(() => {
        divData.scrollTop += 1;
        if (
          divData.clientHeight + divData.scrollTop + 1 >
          divData.scrollHeight
        ) {
          if (table.tableData.length > 5) {
            divData.scrollTop = 0;
            this.$emit("updateTableList");
          }
        }
      }, 1000);
      // const that = this;
      // window.onresize = () => {
      //   return (() => {
      //     window.screenHeight = document.body.clientHeight;
      //     that.clientHeight = window.screenHeight;
      //   })();
      // };
    },
  },
};
</script>
<style lang="scss" scoped>
.margin_t20 {
  margin-top: 20px;
}
.store-exception-table  {
  .APs-style {
    color: rgba(157, 142, 243, 1) !important;
  }
  .ESL-style {
    color: rgba(253, 190, 0, 1) !important;
  }
  .Interface-style {
    color: rgba(78, 145, 255, 1) !important;
  }
  .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }
  .el-table::before {
    height: 0;
  }
  .el-table ::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; /*滚动条宽度*/
      height: 1px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: 0px 1px 3px rgba(144, 147, 153, 0.3) inset; /*滚动条的背景区域的内阴影*/
      border-radius: 10px; /*滚动条的背景区域的圆角*/
      background-color: rgba(144, 147, 153, 0.3); /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0px 1px 3px rgba(144, 147, 153, 0.3) inset; /*滚动条的内阴影*/
      border-radius: 10px; /*滚动条的圆角*/
      background-color: rgba(144, 147, 153, 0.3); /*滚动条的背景颜色*/
    }
    // .el-table__body-wrapper ::v-deep {
    //   .el-table__body tbody {
    //     .APs-style {
    //       color: rgba(78, 145, 255, 1) !important;
    //     }
    //     .ESL-style {
    //       color: rgba(253, 190, 0, 1) !important;
    //     }
    //     .Interface-style {
    //       color: rgba(157, 142, 243, 1) !important;
    //     }
    //   }
    // }
    .el-table__header-wrapper .el-table__header .thead tr {
      // background: #2b334c;
      // border-radius: 6px;
    }
  }
}
</style>