<template>
  <div class="chart" style="height: 161px; width: 100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "lodash";
export default {
  props: {
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      let datas = this.options.dataList;
      let dataName = [];
      let dataY = [];
      let defaultData = [];
      let maxData = 0;
      datas.forEach((item) => {
        dataName.push(item.name);
        dataY.push(item.val);
        if (maxData < item.val) {
          maxData = item.val;
        }
      });

      for (let i = 0; i < dataY.length; i++) {
        // defaultData.push(maxData);
        if (this.options.total == 0) defaultData.push(100);
        else defaultData.push(maxData);
      }
      var colorList = this.options.colors;
      this.chart.setOption({
        grid: {
          left: "2%",
          right: "0%",
          bottom: "0%",
          top: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          position: function (point, params, dom, rect, size) {
            return [point[0], point[1]];
          },
          confine: false,
          // formatter: '{b}:{c}'
          formatter: function (params) {
            let title = params[0].name;
            if (title.length > 30) {
              title =
                title.substring(0, 15) +
                "<br/>" +
                title.substring(15, 30) +
                "<br/>" +
                title.substring(30);
            } else if (title.length > 15) {
              title = title.substring(0, 15) + "<br/>" + title.substring(15);
            }
            let name = title + " : " + params[0].value;
            return name;
          },
        },
        xAxis: {
          show: false,
          type: "value",
        },
        // legend: {
        //   selectedMode: false,
        //   orient: "horizontal",
        // left: '5%',
        //   bottom: "0",
        //   icon: "circle",
        // textStyle: {
        //     rich: {
        //         // b: {
        //         //     padding: [0, 0, 0, 20],
        //         //     fontSize: 25,
        //         // },
        //         // c: {
        //         //     padding: [0, 0, 0, 20],
        //         //     fontSize: 25,
        //         // },
        //     },
        //     color: 'red'//字体颜色
        // },
        // data: dataName
        // data:['预警类型一','预警类型二','预警类型三','预警类型四'],
        // formatter:  function(name){
        //     for (var i = 0, l = datas.length; i < l; i++) {

        //     }
        // }
        // },
        yAxis: [
          {
            show: true,
            type: "category",
            inverse: true,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#666666",
                fontSize: "12",
              },
              formatter: function (value) {
                value =
                  value.length > 15 ? value.substring(0, 15) + "..." : value;
                return value;
              },
            },
            data: dataName,
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#666666",
                fontSize: "12",
              },
              formatter: function (value) {
                return value;
              },
            },
            data: dataY,
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: [30, 30, 30, 30],
                color: (params) => {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: "rgba(54,69,129,1)",
                    },
                    {
                      offset: 0.8,
                      color: colorList[params.dataIndex],
                    },
                    {
                      offset: 1,
                      color: "rgba(255,255,255,0.8)",
                    },
                  ]);
                },
                color: (params) => {
                  return colorList[params.dataIndex];
                },
              },
            },
            barWidth: 10,
            data: dataY,
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 10,
            barGap: "-100%",
            data: defaultData,
            // data: [100, 100, 100, 100, 100],
            itemStyle: {
              normal: {
                color: "#F4F4F4",
                barBorderRadius: [30, 30, 30, 30],
              },
            },
          },
        ],
      });
    },
  },
  created: function () {},
  computed: {},
  watch: {
    options: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
};
</script>
