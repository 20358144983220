<template>
    <div class="dashboard-source_link-card dashboard-card screen-card-bg">
        <div class="title"> {{$t("dashboard.admin.Statistics")}} </div>
        <div class="center-wrapper">
            <middium-total-card style="margin-bottom: 20px;" :list="options.statisticList"></middium-total-card>

            <middium-total-card :list="options.statisticListB"></middium-total-card>
        </div>
    </div>
</template>
<script>
import MiddiumTotalCard from './MiddiumTotalCard.vue'
export default {
  components: {
    MiddiumTotalCard,
  },
  props: {
    options: {
        type: Object,
        require: true,
        default: () => ({})
    }
  },
  data() {
    return {

    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.dashboard-source_link-card {
    .dashboard-card {
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
        border-radius: 6px;
        padding: 20px 30px;
    }
    .flex-between {
        display: flex;
        justify-content: space-between;
    }
    .title {
        // font-size: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 25px;
    }
    
    
    .center-wrapper {
    }
   
}
</style>
