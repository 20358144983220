<template>
  <div>
    <i class="el-icon-full-screen screenfull-svg" @click='click'></i> 
	</div>
</template>

<script>
import screenfull from 'screenfull'
export default {
	name: 'screenfull',
	props: {
	    width: {
	      type: Number,
	      default: 22
	    },
	    height: {
	      type: Number,
	      default: 22
	    },
	    fill: {
	      type: String,
	      default: '#48576a'
	    }
	},
  data() {
    return {
      isFullscreen: false
    }
  },
  methods: {
    click(e) {
	    if(screenfull.enabled){
	        screenfull.toggle();
	    }else {
	    }
    }
  }
}
</script>

<style lang="scss" scoped>
.screenfull-svg {
  cursor: pointer;
  font-size: 20px;
  // fill: #555555;
  // vertical-align:middle;
}
</style>
