<template>
  <section class="dashboard_ordinary-store">
    <!-- <el-row class="flex-between width_100">
        <el-col  :span="12" class="margin_r20" style="min-width: 660px">
            <welcome-card/>
        </el-col>
        <el-col :span="12"  >
            <el-row class="width_100 flex-between">
                <el-col :span="12" class="dashboard-card card-right margin_r20  margin_b20"  style="min-width: 300px">
                    <icon-svg name="bz-items"></icon-svg>
                    <span> Items </span>
                    <pie-chart></pie-chart>
                </el-col>
                <el-col :span="12" class="dashboard-card card-right"  style="min-width: 300px">
                    <icon-svg name="bz-transmissions"></icon-svg>
                    <span> Transmissions </span>
                    <ordinary-rank :options="transmissionsData"></ordinary-rank>
                </el-col>
            </el-row>
            <el-row class="width_100  flex-between">
                <el-col :span="12" class="dashboard-card card-right margin_r20" style="min-width: 300px">
                    <icon-svg name="bz-esls"></icon-svg>
                    <span> ESLs </span>
                    <ordinary-rank :options="eslData"></ordinary-rank>
                </el-col>
                <el-col :span="12" class="dashboard-card card-right" style="min-width: 300px">
                    <icon-svg name="bz-aps"></icon-svg>
                    <span> APs </span>
                    <ordinary-rank :options="apsData"></ordinary-rank>
                </el-col>
            </el-row>
        </el-col>
    </el-row> -->
    <el-row :gutter="32" class="width_100" style="margin: 0">
      <el-col
        :md="24"
        :lg="11"
        :xl="11"
        class="paddinng_l0"
        :style="{ paddingRight: screenWidth < 1188 ? 0 : '20px' }"
      >
        <welcome-card :currentOptions="currentOptions" />
      </el-col>
      <el-col :md="24" :lg="13" :xl="13" class="paddinng_r0 paddinng_l0">
        <el-row class="width_100 flex-between">
          <el-col
            :span="12"
            class="dashboard-card card-right margin_r20 margin_b20"
          >
            <icon-svg name="bz-items"></icon-svg>
            <span class="title">
              {{ $t("dashboard.ordinaryStore.Items") }}
            </span>
            <pie-chart :chartData="itemsList"></pie-chart>
          </el-col>
          <el-col :span="12" class="dashboard-card card-right margin_b20">
            <div style="margin-bottom: 25px;">
              <icon-svg name="bz-transmissions"></icon-svg>
              <span class="title">
                {{ $t("dashboard.ordinaryStore.Transmissions") }}
              </span>
            </div>
            <ordinary-rank :options="transmissionsData"></ordinary-rank>
            <!-- <bar-column-chart :options="transmissionsData"></bar-column-chart> -->
          </el-col>
        </el-row>
        <el-row class="width_100 flex-between">
          <el-col
            :span="12"
            class="dashboard-card card-right margin_r20 margin_b20"
          >
            <div style="margin-bottom: 25px;">
              <icon-svg name="bz-esls"></icon-svg>
              <span class="title"> {{ $t("dashboard.ordinaryStore.ESLs") }}</span>
            </div>
            <ordinary-rank :options="eslData"></ordinary-rank>
            <!-- <bar-column-chart :options="eslData"></bar-column-chart> -->
          </el-col>
          <el-col :span="12" class="dashboard-card card-right">
           <div style="margin-bottom: 25px;">
              <icon-svg name="bz-aps"></icon-svg>
              <span class="title"> {{ $t("dashboard.ordinaryStore.APs") }} </span>
           </div>
            <ordinary-rank :options="apsData"></ordinary-rank>
            <!-- <bar-column-chart :options="apsData"></bar-column-chart> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row
      :gutter="32"
      class="width_100 dashboard-center-wrapper"
      style="margin-left: 0px"
    >
      <el-col :md="24" :lg="8" class="paddinng_r0 paddinng_l0">
        <div class="dashboard-card margin_b20">
          <div class="flex-between dashboard-center-wrapper_toptitle">
            <span class="title"> {{ $t("dashboard.ordinaryStore.ESL Updated Statictic") }}</span>
            <!-- <span class="time">{{
              moment(currentOptions.SystemTime).format("MM.DD HH:mm:ss")
            }}</span> -->
          </div>
          <esl-update-static-chart :options="eslUpdateStatisticData"></esl-update-static-chart>
        </div>
      </el-col>
      <el-col
        :md="24"
        :lg="8"
        :style="{
          paddingLeft: screenWidth < 1188 ? 0 : '20px',
          paddingRight: 0,
        }"
      >
        <div class="dashboard-card margin_b20">
          <div class="flex-between dashboard-center-wrapper_toptitle">
            <span class="title">{{
              $t("dashboard.ordinaryStore.NubmerOfIntegratedLines")
            }}</span>
            <!-- <span class="time">
              {{ moment(currentOptions.SystemTime).format("MM.DD HH:mm:ss") }}
            </span> -->
          </div>
          <intergrated-lines-chart
            :options="IntegratedLinesData"
          ></intergrated-lines-chart>
        </div>
      </el-col>
      <el-col
        :md="24"
        :lg="8"
        :style="{
          paddingLeft: screenWidth < 1188 ? 0 : '20px',
          paddingRight: 0,
        }"
      >
        <div class="dashboard-card margin_b20">
          <div class="flex-between dashboard-center-wrapper_toptitle">
            <span class="title">{{
              $t("dashboard.ordinaryStore.TemplateStatistics")
            }}</span>
            <!-- <span class="time">
              {{ moment(currentOptions.SystemTime).format("MM.DD HH:mm:ss") }}
            </span> -->
          </div>
          <bar-vertical-chart
            :options="templateStatisticsData"
          ></bar-vertical-chart>
        </div>
      </el-col>
    </el-row>
    <el-row class="width_100 dashboard-card dashboard-bottom-wrapper">
      <div class="dashboard-bottom-wrapper_btn">
        <div class="title">{{ $t("dashboard.ordinaryStore.LatestTask") }}</div>
        <el-button
          @click="handelMore"
          class="more-btn"
          type="success"
          size="small"
          round
          >{{ $t("dashboard.ordinaryStore.More") }}</el-button
        >
      </div>
      <lastest-task-table :tableData="getTaskLogList"></lastest-task-table>
    </el-row>
  </section>
</template>
<script>
// 普通门店
import WelcomeCard from "../components/WelcomeCard.vue";
import PieChart from "../echarts/PieChart.vue";
import BarColumnChart from "../echarts/BarColumnChart.vue";
import EslUpdateStaticChart from "../echarts/EslUpdateStaticChart.vue";
import IntergratedLinesChart from "../echarts/IntergratedLinesChart.vue";
import BarVerticalChart from "../echarts/BarVerticalChart.vue";
import LastestTaskTable from "../components/LastestTaskTable.vue";
import OrdinaryRank from "../components/OrdinaryRank.vue";

import { commonStore } from "@/store/global-select-store";
import {
  countEslUpdateToday,
  getCountAp,
  getCountEsl,
  getCountTransmissions,
  getCountItems,
  getCountUpdateSource,
  getCountTemplateStatistics,
  getCountNumberOfIntegratedLines,
  getCountEslUpdatedStatic,
  getTaskScheduleLogList,
} from "@/libs/api/dashboard";
import moment from "moment";
export default {
  components: {
    WelcomeCard,
    PieChart,
    BarColumnChart,
    EslUpdateStaticChart,
    IntergratedLinesChart,
    BarVerticalChart,
    LastestTaskTable,
    OrdinaryRank,
  },
  data() {
    return {
      storeId: localStorage.getItem("storeId"),
      commonStore,
      /*transmissionsData: {
            dataList: [],
            colors: ['#FF0000', '#3BC279', '#3461FF'],
            total: 0
        },
        eslData: {
            dataList: [],  
            colors: ['#B1B1B1', '#B3C4FF', '#3461FF'],
            total: 0
        },
        apsData: {
            dataList: [],  
            colors: ['#B1B1B1', '#3461FF'],
            total: 0
        },*/
      moment,

      currentOptions: {
        Total: "",
        PDA: "",
        System: "",
        SystemTime: "",
      },
      transmissionsData: {
        dataList: [
          // { name: 'Errors', val: 12, customColor: '255, 0, 0' },
          // { name: 'Transferred', val: 18, customColor: '59, 194, 121' },
          // { name: 'Total', val: 15, customColor: '52, 97, 255' }
        ],
        total: 0,
        maxNum: 0,
      },
      eslData: {
        dataList: [],
        total: 0,
        maxNum: 0,
      },
      apsData: {
        dataList: [],
        total: 0,
        maxNum: 0,
      },
      itemsList: [],

      templateStatisticsData: {
        XData: [],
        valueData: [],
        title: this.$t("dashboard.ordinaryStore.TemplateStatistics")
      },
      IntegratedLinesData: {
        barDataOK: [],
        barDataErrors: [],
        date: [],
        okText: '',
        errorText: ''
      },
      eslUpdateStatisticData: {},
      getTaskLogList: [],

      screenWidth: document.body.clientWidth,
      columnColors: {
        Errors: "255, 0, 0",
        Transferred: "59, 194, 121",
        Total: "52, 97, 255",
        "Off-line": "177, 177, 177",
        Unlink: "179, 196, 255",
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language
    }
  },
  watch: {
    language(val) {
      if(val) this.updateLanguage();
    },
  },
  created() {
    commonStore.setInjectCallBack(this.initData);
    this.initData();   
  },
  mounted() {
    window.addEventListener("resize", this.watchWindowSize);
  },
  methods: {
    watchWindowSize() {
      this.screenWidth = document.documentElement.clientWidth;
    },
    handelMore() {
      this.$router.push({
        path: "/logs/task-logs",
      });
    },
    updateLanguage() {
      this.getCountEsl();
      this.getCountAp();
      this.getCountTransmissions();
      this.getCountItems();

      this.getCountTemplateStatistics();
      this.getCountNumberOfIntegratedLines();
      this.getCountEslUpdatedStatic();
      this.getTaskScheduleLogList();
    },
    initData() {
      this.getCountUpdateSource();
      this.getCountEslUpdateToday();
      this.getCountEsl();
      this.getCountAp();
      this.getCountTransmissions();
      this.getCountItems();

      this.getCountTemplateStatistics();
      this.getCountNumberOfIntegratedLines();
      this.getCountEslUpdatedStatic();
      this.getTaskScheduleLogList();
    },

    async getTaskScheduleLogList() {
      try {
        let params = { current: 1, size: 10 };
        const { data } = await getTaskScheduleLogList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          this.getTaskLogList = dataNew.records;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountEslUpdatedStatic() {
      try {
        const { data } = await getCountEslUpdatedStatic({
          storeId: this.storeId,
        });
        if (data.state === true) {
          let dataNew = data.data || {};
          let dataArr = dataNew.data || [];
          
          dataArr = dataArr.sort(function(a, b) {
            let nameA = a.name && (a.name.indexOf('-') > -1) ? a.name.split('-')[0] : a.name;
            let nameB = b.name && (b.name.indexOf('-') > -1) ? b.name.split('-')[0] : b.name;
            return nameA - nameB;
            // return a.name.localeCompare(b.name, 'zh-CN', { numeric: true })
          })

          this.eslUpdateStatisticData = {
            data: dataArr,
            date: dataNew.date || [],
          };
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountNumberOfIntegratedLines() {
      try {
        const { data } = await getCountNumberOfIntegratedLines({
          storeId: this.storeId,
        });
        if (data.state === true) {
          let dataNew = data.data || {};

          let barDataOK = dataNew.okArr.map((item) => item.value);
          let barDataErrors = dataNew.errorArr.map((item) => item.value);
          this.IntegratedLinesData = {
            barDataOK: barDataOK || [],
            barDataErrors: barDataErrors || [],
            date: dataNew.date || [],
            okText: dataNew.okArr && dataNew.okArr.length ? dataNew.okArr[0].name : this.$t('dashboard.ordinaryStore.Ok'),
            errorText: dataNew.errorArr && dataNew.errorArr.length ? dataNew.errorArr[0].name : this.$t('dashboard.ordinaryStore.Error')
          };
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountTemplateStatistics() {
      try {
        const { data } = await getCountTemplateStatistics({
          storeId: this.storeId,
        });
        if (data.state === true) {
          let dataList = data.data || [];
          let XData = dataList.map((item) => item.name);
          let valueData = dataList.map((item) => item.value);
          this.templateStatisticsData.XData = XData;
          this.templateStatisticsData.valueData = valueData;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountEslUpdateToday() {
      try {
        const { data } = await countEslUpdateToday({ storeId: this.storeId });
        if (data.state === true) {
          let datalist = data.data || [];
          this.currentOptions.Total = datalist[0].value;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountUpdateSource() {
      try {
        const { data } = await getCountUpdateSource({ storeId: this.storeId });
        if (data.state === true) {
          let datalist = data.data || [];
          datalist.forEach((item) => {
            this.currentOptions[item.key] = item.value;
          });
          // let dataNew = data.data || {};
          // this.currentOptions = Object.assign(this.currentOptions, dataNew);
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountTransmissions() {
      try {
        const { data } = await getCountTransmissions({ storeId: this.storeId });
        if (data.state === true) {
          let dataList = data.data || [];
          dataList.forEach((item) => {
            for (let key in this.columnColors) {
              if (item.key === key) {
                item.customColor = this.columnColors[key];
                break;
              }
            }
          });
          const arr = dataList.map(({ value }) => value);
          this.transmissionsData.dataList = dataList;
          this.transmissionsData.maxNum = Math.max(...arr)
            ? Math.max(...arr)
            : 0;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountAp() {
      try {
        //基站aps
        const { data } = await getCountAp({ storeId: this.storeId });
        if (data.state === true) {
          let dataList = data.data || [];
          dataList.forEach((item) => {
            for (let key in this.columnColors) {
              if (item.key === key) {
                item.customColor = this.columnColors[key];
                break;
              }
            }
          });
          const arr = dataList.map(({ value }) => value);
          this.apsData.dataList = dataList;
          this.apsData.maxNum = Math.max(...arr) ? Math.max(...arr) : 0;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountEsl() {
      try {
        const { data } = await getCountEsl({ storeId: this.storeId });
        if (data.state === true) {
          let dataList = data.data || [];
          dataList.forEach((item) => {
            for (let key in this.columnColors) {
              if (item.key === key) {
                item.customColor = this.columnColors[key];
                break;
              }
            }
          });
          const arr = dataList.map(({ value }) => value);
          this.eslData.dataList = dataList;
          this.eslData.maxNum = Math.max(...arr) ? Math.max(...arr) : 0;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getCountItems() {
      try {
        const { data } = await getCountItems({ storeId: this.storeId });
        if (data.state === true) {
          let dataList = data.data || [];
          this.itemsList = dataList;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard_ordinary-store {
  .paddinng_r0 {
    padding-right: 0 !important;
  }
  .paddinng_l0 {
    padding-left: 0 !important;
  }
  .width_100 {
    width: 100%;
  }
  .margin_r20 {
    margin-right: 20px;
  }
  .margin_l20 {
    margin-left: 20px;
  }
  .margin_b20 {
    margin-bottom: 20px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .dashboard-card {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 20px 30px;
    .title {
      display: inline-block;
      font-size: 16px;
    }
  }
  .card-right {
    // height: 185px;
    height: 225px;
    span {
      color: #333333;
    }
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .dashboard-center-wrapper {
    // font-size: 18px;
    .title {
      color: #333333;
    }
    .time {
      color: rgba(68, 68, 68, 0.5);
      font-size: 12px;
    }
    &_toptitle {
      // height: 50px;
      height: 40px;
    }
  }
  .dashboard-bottom-wrapper {
    .title {
      // padding-top: 8px;

    }
    .el-button.is-round {
      border-radius: 20px !important;
      padding: 7px 13px !important;
      font-size: 12px;
      // margin-bottom: 20px;
      margin-bottom: 10px;
    }
    &_btn {
      display: flex;
      justify-content: space-between;
      // align-items: center;
    }
    .more-btn {
      background: #35b871 !important;
      box-shadow: 0 3px 4px 0 rgb(40 199 111 / 28%) !important;
    }
  }
}
</style>
