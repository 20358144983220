<template>
  <div class="chart" style="height:126px; width:100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from 'lodash';
export default {
  props: {
    options: {
        type: Object,
        require: true,
        default: () => ({})
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    if(this.options.chartData && this.options.chartData.length) {
        this.initChart();
    }
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
        this.chart = echarts.init(this.$el, "macarons");
        const _this = this;
        this.chart.setOption({
            color: this.options.colors,
            grid: {
                // left: '5%',
                right: '30%',
                // bottom: '0%',
                // top: '0%',
                // containLabel: true,
            },
            tooltip: {
            confine: true,
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['50%', '75%'],
                    center: ['60%', '50%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        normal: {
                            label: { //此处为指示线文字
                                show: false
                            },
                        }
                    },
                    label: {
                    // show: true,
                    position: 'center',
                    },
                    labelLine: {
                    show: false,
                    },
                    data: this.options.chartData,
                },
            ],
        });
    }
  },
  created: function() {},
  computed: {},
  watch: {
    options: {
      handler() {
        this.initChart()
      },
      deep:true,
    },
  },
};
</script>
