<template>
  <div class="chart" id="echartsCon" style="height:165px; width:100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from 'lodash';
export default {
  props: {
    chartData: {
      type: Array,
      require: true,
      default: () => ([])
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.chart = echarts.init(document.getElementById("echartsCon"));
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      const _this = this;
      
      // 字段值对齐，不足补空格
      let fixWithWhiteSpace = (maxLen, text, prefix = true) => {
      if (text && maxLen) {
          let num = maxLen - text.length
          return prefix && num > 0 ? Array(num + 1).join('  ') + text : text + Array(num + 1).join('   ')
      }
        return text
      }
      this.chart.setOption({
        color: ['#FF0000', '#B3C4FF', '#3461FF'],
        grid: {
            left: '0%',
            right: '0%',
            bottom: '5%',
            top: '0%',
            // containLabel: true,
        },
        tooltip: {
          confine: true,
        },
        legend: {
          selectedMode: false,
          orient: 'vertical',
          left: '5%',
          top: '25%',
          icon: "circle",
          textStyle: {
              fontSize: 12,
              color: '#A8CCDE',
              verticalAlign: 'middle',
          },
          itemWidth: 10,
          itemHeight: 10,
            /*formatter(name) { // 该函数用于设置图例显示后的百分比
                let data = chartData
                let total = 0
                let value = 0
                data.forEach((item) => {
                    total += item.value
                    if (item.name == name) {
                    value = item.value
                    }
                })
                let p = ((value / total) * 100).toFixed(1) // 求出百分比

                let maxNamelen = Math.max(...data.map(p => p.name.length))
                let maxValuelen = Math.max(...data.map(p => (p.value + '').length))
                let maxPercentageLen = Math.max(...data.map(p => ((p.value / total) * 100).toFixed(1).length))
                // 返回出图例所显示的内容是名称+百分比
                return [`${fixWithWhiteSpace(maxNamelen, name, false)}` + '{b|' + `${fixWithWhiteSpace(maxValuelen, (value || 0).toString())}` + '}{c|' + `${fixWithWhiteSpace(maxPercentageLen, p || '0')}%` + '}']
            },*/
            textStyle: {
                rich: {
                    b: {
                        padding: [0, 0, 0, 20],
                        fontSize: 12,
                    },
                    c: {
                        padding: [0, 0, 0, 20],
                        fontSize: 12,
                    },
                },
            },
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '85%'],
                center: ['65%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                normal: {
                  label: { //此处为指示线文字
                        show: true,
                        position: 'inner', //标签的位置
                        textStyle: {
                            // fontWeight: 200,
                            fontSize: 9
                        },
                        formatter: function (item) {   
                          // let data = item.data.value;

                          let chartDataNew = _this.chartData || [];
                          let total = 0;
                          let value = 0;
                          chartDataNew.forEach((itemVal) => {
                            total += itemVal.value;
                            if (itemVal.name == item.data.name) {
                              value = itemVal.value;
                            }
                          })
                          let p = value ? ((value / total) * 100).toFixed(1) : 0; // 求出百分比
                          // console.log(p)
                          // return data;
                          return p + '%';
                        }
                      },
                  }
              },
                label: {
                  // show: true,
                  position: 'center',
                },
                labelLine: {
                  show: false,
                },
                data: this.chartData,
            },
        ],
      });
    }
  },
  created: function() {},
  computed: {},
  watch: {
    chartData: {
      deep:true,
      handler() {
        this.initChart()
      },
    },
  },
};
</script>
