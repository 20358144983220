import { render, staticRenderFns } from "./PieCard.vue?vue&type=template&id=2c88ab99&scoped=true&"
import script from "./PieCard.vue?vue&type=script&lang=js&"
export * from "./PieCard.vue?vue&type=script&lang=js&"
import style0 from "./PieCard.vue?vue&type=style&index=0&id=2c88ab99&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c88ab99",
  null
  
)

export default component.exports