import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;


// 统计ESL Update Today 模块相关数据
function countEslUpdateToday(data) {
  return router({
    url: baseUrl+"home/v1/countEslUpdateToday",
    data: data,
    method: "POST",
  });
}
// 统计Items模块相关数据 
function getCountItems(data) {
    return router({
      url: baseUrl+"home/v1/countItems",
      data: data,
      method: "POST",
    });
}
// 统计Transmissions模块相关数据
function getCountTransmissions(data) {
    return router({
      url: baseUrl+"home/v1/countTransmissions",
      data: data,
      method: "POST",
    });
}
// 统计Update Source 模块相关数据
function getCountUpdateSource(data) {
    return router({
      url: baseUrl+"home/v1/countUpdateSource",
      data: data,
      method: "POST",
    });
}
// 统计基站模块相关数据
function getCountAp(data) {
    return router({
      url: baseUrl+"home/v1/countAp",
      data: data,
      method: "POST",
    });
}
// 统计价签模块相关数据
function getCountEsl(data) {
    return router({
        url: baseUrl+"home/v1/countEsl",
        data: data,
        method: "POST",
    });
}

// 统计Template statistics模块相关数据  
function getCountTemplateStatistics(data) {
  return router({
      url: baseUrl+"home/v1/countTemplateStatistics",
      data: data,
      method: "POST",
  });
}
// 统计Number of Integrated lines模块相关数据 
function getCountNumberOfIntegratedLines(data) {
  return router({
      url: baseUrl+"home/v1/countNumberOfIntegratedLines",
      data: data,
      method: "POST",
  });
}
// 统计ESL Updated Static模块相关数据
function getCountEslUpdatedStatic(data) {
  return router({
      url: baseUrl+"home/v1/countEslUpdatedStatic",
      data: data,
      method: "POST",
  });
}
// 获取任务日志列表  获取任务日志列表
function getTaskScheduleLogList(data) {
  return router({
      url: baseUrl+"log/v1/getTaskScheduleLogList",
      data: data,
      method: "POST",
      header:{
        'Content-Type':'application/json'
      }
  });
}
// 获取错误日志列表
function getErrorList(data) {
  return router({
      url: baseUrl+"schedule/v1/getErrorDetailList",
      // url: baseUrl+"log/v1/getErrorList",
      data: data,
      method: "POST"
  });
}

// admin
// 统计时序图
function countHourEsl(data) {
  return router({
      url: baseUrl+"home/admin/v1/countHourEsl",
      data: data,
      method: "POST"
  });
}
// 统计价签数据
function countEsl(data) {
  return router({
      url: baseUrl+"home/admin/v1/countEsl",
      data: data,
      method: "POST"
  });
}

// TOP5统计  统计基站模块相关数据
function getTop(data) {
  return router({
      url: baseUrl+"home/admin/v1/top",
      data: data,
      method: "POST"
  });
}

// 异常数量统计  异常数量统计  
function countExStat(data) {
  return router({
      url: baseUrl+"home/admin/v1/countExStat",
      data: data,
      method: "POST"
  });
}
// 异常数量统计  异常数量统计  
function countStat(data) {
  return router({
      url: baseUrl+"home/admin/v1/countStat",
      data: data,
      method: "POST"
  });
}
// 异常详情统计
function countExDetail(data) {
  return router({
      url: baseUrl+"home/admin/v1/countExDetail",
      data: data,
      method: "POST"
  });
}
export { 
  getTaskScheduleLogList, 
  getCountEslUpdatedStatic, 
  getCountNumberOfIntegratedLines, 
  getCountTemplateStatistics, 
  countEslUpdateToday, 
  getCountAp, 
  getCountEsl, 
  getCountTransmissions, 
  getCountItems, 
  getCountUpdateSource,
  getErrorList,

  countHourEsl,
  countEsl,
  getTop,
  countExStat,
  countStat,
  countExDetail
}