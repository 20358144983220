<template>
    <div class="dashboard_middium-card">
        <el-col class="dashboard_middium-card-item flex-between" :span="8" v-for="(item, i) in list" :key="i" :style="{background: 'rgba(' + item.customColor + ',1)',boxShadow: '0px 7px 9px 0px rgba('+ item.customColor + ',0.42)'}">
            <div class="number">{{item.val}}</div>
            <div class="name">{{item.name}}</div>
        </el-col>
    </div>
</template>
<script>
export default {
  components: {},
  props: {
    list: {
        type: Array,
        require: true,
        default: () => ([])
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.dashboard_middium-card {
    display: flex;
    justify-content: space-between;
    &-item {
        text-align: center;
        padding: 35px 25px;
        border-radius: 10px;
        margin-right: 16px;
        // height: 131px;
        height: 114px;
        .number {
            font-size: 30px;
            color: #FFFFFF;
        }
        .name {
            // font-size: 15px;
            font-size: 14px;
            color: #FFFFFF;
        }
    }
    &-item:last-of-type {
        margin-right: 0;
    }
}
</style>
