var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"taskName","label":_vm.$t('dashboard.ordinaryStore.TaskName'),"sortable":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"taskType","align":"center","label":_vm.$t('dashboard.ordinaryStore.Task')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"task tast-bg"},[_vm._l((_vm.icons),function(item,i){return [(scope.row.taskType == i)?_c('span',{key:i},[_c('icon-svg',{staticStyle:{"font-size":"15px"},attrs:{"name":_vm.icons[i]}}),_vm._v(" "+_vm._s(scope.row.taskTypeDesc)+" ")],1):_vm._e()]})],2)]}}])}),_c('el-table-column',{attrs:{"prop":"startDate","label":_vm.$t('dashboard.ordinaryStore.BeginTime'),"sortable":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"state","label":_vm.$t('dashboard.ordinaryStore.Status'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._l((_vm.taskStateList),function(item,i){return [(scope.row.state == item.codeId)?_c('span',{key:i,staticClass:"status",class:{
              'status-todo':
                scope.row.state == _vm.TASK_STATE_INIT ||
                scope.row.state == _vm.TASK_STATE_WAIT_EXPECT,
              'status-progress': scope.row.state == _vm.TASK_STATE_INPROGRESS,
              'status-complete':
                scope.row.state == _vm.TASK_STATE_SUCCESS ||
                scope.row.state == _vm.TASK_STATE_PART_SUCCESS,
              'status-fail': scope.row.state == _vm.TASK_STATE_ERROR,
            }},[_vm._v(" "+_vm._s(scope.row.stateDesc)+" ")]):_vm._e()]})]}}])}),_c('el-table-column',{attrs:{"prop":"processeds","label":_vm.$t('dashboard.ordinaryStore.SuccessNumber'),"sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.processeds)?_c('span',[_vm._v("0")]):_c('span',[_vm._v(_vm._s(scope.row.processeds))])]}}])}),_c('el-table-column',{attrs:{"prop":"errors","label":_vm.$t('dashboard.ordinaryStore.ErrorsNumber'),"sortable":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[(!scope.row.errors)?_c('span',[_vm._v("0")]):_c('span',[_vm._v(_vm._s(scope.row.errors))]),_c('span',{staticClass:"search-icon",on:{"click":function($event){return _vm.handelView(scope.row)}}},[_c('i',{staticClass:"el-icon-search"})])])]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.$t('dashboard.ordinaryStore.Failure Details'),"visible":_vm.isVisibleDetailDialog,"width":"800px"},on:{"update:visible":function($event){_vm.isVisibleDetailDialog=$event}}},[(_vm.isVisibleDetailDialog)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.errorList,"header-cell-style":{ 'text-align': 'center' },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.ordinaryStore.seq'),"align":"center","prop":"tagIdentity"}}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.ordinaryStore.message'),"align":"center","prop":"message"}}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.ordinaryStore.expireTime'),"align":"center","prop":"createTime"}})],1):_vm._e(),_c('pagination',{attrs:{"tableInfo":_vm.pageParams},on:{"changePage":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }