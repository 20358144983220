<template>
    <div class="dashboard-page">
        <!-- 普通门店 -->
        <!-- <ordinary-store></ordinary-store> -->

        <component :timeFormat="timeFormat" :is="currentComponent.component"></component>
    </div>
</template>
<script>
// 普通门店
import OrdinaryStore from './modules/OrdinaryStore'
// 经销商 
import Distributor from './modules/Distributor' 
// 商户总部
import BusinessDepartment from './modules/BusinessDepartment' 
// admin
import Admin from './modules/Admin'

import { getTimeConfig } from '@/libs/api/system-parameters'
export default {
  directives: {},
  components: {
    OrdinaryStore,
  },
  data() {
    return {
      userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
      currentComponent: {
        role: 'Ordinary',
        component: OrdinaryStore
        // component: Admin
        // component: Distributor
        // component: BusinessDepartment
      },
      componentsList: [
        {
          userType: '201', // 经销商
          component: Distributor
        },
        {
          userType: '202', // 运维
          component: Distributor
        },
        {
          userType: '203', // 总部用户（商户总部）
          component: BusinessDepartment
        },
        {
          userType: '204', // 门店用户（普通用户）
          component: OrdinaryStore
        },
        {
          userType: '205', // Admin首页
          component: Admin
        },
      ],
      timeFormat: 'YYYY-MM-DD HH:mm:ss',
    }
  },
  created() {
    let result = this.componentsList.find(item=> {
      return item.userType == this.userData.userType;
    });
    this.currentComponent = result ? result : this.currentComponent;
    // this.currentComponent = this.currentComponent;
    

    this.getTimeConfig();
    },
  mounted() {},
  computed: {},
  methods: {
    async getTimeConfig() {
      try {
        let params = {};
        if (this.userData.userType === 203) { 
          params = { merchantId: localStorage.getItem('merchantId') };
        } else {
          params = { storeId: localStorage.getItem('storeId') };
        }
        const { data } = await getTimeConfig(params);
        if (data.state === true) {
          let dataNew = data.data ? data.data : {};
          this.timeFormat = dataNew.allFormat ? dataNew.allFormat : 'YYYY-MM-DD HH:mm:ss';
          if(this.timeFormat) {
            let dIndex = this.timeFormat.indexOf('d');
            if(dIndex>-1) {
              this.timeFormat = this.timeFormat.replace(new RegExp('d','g'),"D");
            }
          }
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
  },
}
</script>
<style lang="scss">
.dashboard-page {

}
</style>
