<template>
  <div class="chart" style="height: 250px; width: 100%"></div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "lodash";
import moment from "moment";
export default {
  props: {
    chartData: {
      type: Object,
      require: true,
      default: () => {},
    },
    isLScreenStyle: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    });
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      const { islscreen } = this.$route.query;
      const { gridlinesColor, xAxisColor, yAxisColor } = this.isLScreenStyle;
      let datas = [
        // ['2022-10-28 00:00', 0],
        // ['2022-10-28 01:00', 0],
        // ['2022-10-28 02:00', 0],
        // ['2022-10-28 03:00', 0],
        // ['2022-10-28 04:00', 0],
        // ['2022-10-28 05:00', 0],
        // ['2022-10-28 06:00', 0],
        // ['2022-10-28 07:00', 0],
        // ['2022-10-28 08:00', 0],
        // ['2022-10-28 09:00', 0],
        // ['2022-10-28 10:00', 0],
        // ['2022-10-28 11:00', 0],
        // ['2022-10-28 12:00', 6],
        // ['2022-10-28 13:00', 0],
        // ['2022-10-28 14:00', 0],
        // ['2022-10-28 15:00', 0],
        // ['2022-10-28 16:00', 0],
        // ['2022-10-28 17:00', 0],
        // ['2022-10-28 18:00', 0],
        // ['2022-10-28 19:00', 0],
        // ['2022-10-28 20:00', 0],
        // ['2022-10-28 21:00', 0],
        // ['2022-10-28 22:00', 0],
        // ['2022-10-28 23:00', 0],
      ];
      this.chart.setOption({
        grid: {
          top: "5%",
          left: "3%",
          right: "1%",
          bottom: "15%",
          // containLabel: true
        },
        xAxis: [
          {
            // type: "time",
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: islscreen ? gridlinesColor : "#EFEFEF",
              },
            },
            axisLabel: {
              // formatter: "{HH}:{mm}",
              interval: 0,
              rotate: 35,
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: islscreen ? xAxisColor : "#666",
                // padding: 12,
                fontSize: 12,
              },
              // formatter: function (data) {
              //   return moment(data).format("HH:mm");
              // },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: [islscreen ? gridlinesColor : "#EBEEF5"],
              },
            },
            splitNumber: 25,
            data: this.chartData.xData,
          },
        ],
        yAxis: [
          {
            splitLine: {
              show: true,
              lineStyle: {
                color: [islscreen ? gridlinesColor : "#EBEEF5"],
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: islscreen ? gridlinesColor : "#EFEFEF",
              },
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              show: true,
              rotate: 0,
              fontSize: 12,
              color: islscreen ? yAxisColor : "#666",
            },
            splitArea: { show: false },
          },
        ],
        series: [
          {
            type: "line",
            showAllSymbol: true,
            // symbol: 'circle',
            // symbolSize: 12,
            smooth: true,
            sampling: "lttb",
            lineStyle: {
              normal: {
                width: 2,
                color: "#00C0FF", // 线条颜色
              },
              type: "solid",
              // borderColor: 'rgba(0,0,0,.4)',
            },
            // itemStyle: {
            //     color: '#001d42',
            //     borderWidth: 2,
            //     borderColor: '#00C0FF',
            // },
            tooltip: {
              show: true,
            },
            label: {
              show: false,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0, 192, 255,.6)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 192, 255, 0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 192, 255, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: this.chartData.yData,
          },
        ],
      });
    },
  },
  created: function () {},
  computed: {},
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
};
</script>
